<template>
  <div>
    <v-simple-table v-if="tableData">
      <template v-slot:default>
        <table>
          <tr v-for="(row, rIdx) in rows" :key="rIdx">
            <td v-for="(cell, cIdx) in row.cells" :key="cIdx">
              {{ cell.value || "" }}
            </td>
          </tr>
        </table>
      </template>
    </v-simple-table>
    <div v-if="errorString" v-html="errorString" />
  </div>
</template>
<script>
export default {
  props: {
    content: { type: String, required: true }
  },
  data() {
    return { tableData: null, rows: [], errorString: null };
  },
  methods: {
    createTable() {
      var deserializedString = this.tableData;
      if (!(deserializedString.cells && deserializedString.cells.length)) {
        return undefined;
      }
      var hasContent = false;
      var i = 0;
      var rows = [];
      var row = { id: -1, cells: [] };
      var cell = { type: "header", position: "-1:-1" };
      row.cells.push(cell);
      for (var ci = 0; ci < deserializedString.columns.length; ci++) {
        cell = {
          type: "header",
          value: deserializedString.columns[ci],
          position: "-1:" + ci
        };
        row.cells.push(cell);
      }
      rows.push(row);
      i = 0;
      for (var ri = 0; ri < deserializedString.rows.length; ri++) {
        row = { id: ri, cells: [] };
        cell = {
          type: "header-row",
          value: deserializedString.rows[ri],
          position: ri + ":-1"
        };
        row.cells.push(cell);
        for (var ci2 = 0; ci2 < deserializedString.columns.length; ci2++) {
          cell = {
            type: "value",
            value: deserializedString.cells[i],
            position: ci2 + ":" + ri
          };
          row.cells.push(cell);
          if (ci2 == deserializedString.columns.length - 1) {
            hasContent = hasContent || cell.value;
          }
          i++;
        }
        rows.push(row);
      }
      if (hasContent) this.rows = rows;
      return false;
    }
  },
  created() {
    try {
      this.tableData = JSON.parse(this.content);
      this.createTable();
    } catch (e) {
      this.errorString = this.content;
    }
  }
};
</script>

<style scoped lang="scss">
table {
  width: 100%;
  border-collapse: collapse;
  th {
    font-weight: bold;
    border: 1px solid #ccc;
    padding: 5px;
  }

  td {
    border: 1px solid #ccc;
    padding: 9px 11px;
    &.nutritional-header {
      font-weight: bold;
      color: #333;
    }
  }

  td:first-child {
    background-color: #ebebeb;
    font-weight: bold;
    font-size: 14px;
  }

  tr:first-child {
    background-color: #ebebeb;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
  }

  tr {
    font-size: 14px;
  }
}
</style>
