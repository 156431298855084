<template>
  <!-- <div v-if="typeof rendered === 'string'" v-html="rendered" /> -->
  <!-- <div v-else-if="rendered.length"> -->
  <div v-if="rendered.length">
    <div v-for="(item, index) in rendered" :key="index" v-html="item" />
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>
<script>
import { getItemsMapText } from "./templateFunctions";
import forEach from "lodash/forEach";
export default {
  props: {
    content: { type: String, required: true }
  },
  data() {
    return { rendered: [] };
  },
  methods: {
    getValue(map) {
      return getItemsMapText(map);
    }
  },
  created() {
    try {
      let _this = this;
      let mapsArray = JSON.parse(this.content);
      mapsArray.map(i => {
        forEach(i["items-map"].allergens.options, o => {
          for (var r = 0; r < _this.rendered.length; r++) {
            var found = false;
            if (
              _this.rendered[r].indexOf(o.label) > -1 ||
              _this.rendered[r].indexOf(o.key) > -1
            ) {
              found = true;
            }
          }
          if (!found) _this.rendered.push(o.label || o.key);
        });
      });
    } catch (e) {
      // WARNING: in some cases content is a string and NOT a stringified JSON
      this.rendered = this.content;
    }
  }
};
</script>
