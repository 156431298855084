<template>
  <div v-if="typeof rendered === 'string'" v-html="rendered" />
  <div v-else-if="rendered.length">
    <div v-for="item in rendered" :key="item.key">
      <div v-html="getValue(item['items-map'])" />
    </div>
  </div>
</template>
<script>
import { getItemsMapText } from "./templateFunctions";
export default {
  props: {
    content: { type: String, required: true }
  },
  data() {
    return { rendered: [] };
  },
  methods: {
    getValue(map) {
      return getItemsMapText(map);
    }
  },
  created() {
    try {
      this.rendered = JSON.parse(this.content);
    } catch (e) {
      // WARNING: in some cases content is a string and NOT a stringified JSON
      this.rendered = this.content;
    }
  }
};
</script>
