<template>
  <div v-html="content" />
</template>
<script>
export default {
  props: {
    content: { type: [String, Array], required: true },
    append: { type: String }
  }
};
</script>
