<template>
  <div class="product-images">
    <v-row no-gutters>
      <v-col cols="12" md="12" class="d-flex justify-center">
        <div
          :style="{
            width: $vuetify.breakpoint.xs ? 'auto' : '400px',
            border: '1px'
          }"
          @mouseleave="disableZoom = false"
        >
          <img
            class="mt-2"
            width="100%"
            v-if="$vuetify.breakpoint.xs"
            :src="selectedImage.medium"
            :alt="'Immagine prodotto ' + name"
            @click.stop="dialog = true"
          />

          <zoom-on-hover
            v-else
            :disabled="disableZoom"
            :scale="1.5"
            :img-normal="selectedImage.medium"
            :img-zoom="selectedImage.large"
          ></zoom-on-hover>
        </div>
      </v-col>

      <v-col
        cols="12"
        md="12"
        class="d-flex justify-center thumb-wrapper"
        v-if="images.length > 1"
      >
        <swiper :options="swiperOptionThumbs" class="swiper" ref="swiperThumb">
          <swiper-slide v-for="media in images" :key="media.medium">
            <img
              :key="'img_' + media.medium"
              :src="media.small"
              :alt="'Anteprima immagine prodotto ' + name"
              :class="{ selected: selectedImage == media }"
              @click="selectedImage = media"
            />
          </swiper-slide>
        </swiper>
        <div class="swiper-button-next" slot="button-next"></div>
        <div class="swiper-button-prev" slot="button-prev"></div>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="zoom-dialog"
    >
      <div class="zoom-dialog-content">
        <v-btn @click.stop="dialog = false" dense elevation="0">Chiudi</v-btn>
        <pinch-zoom height="100%">
          <img :src="selectedImage.large" />
        </pinch-zoom>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import PinchZoom from "vue-pinch-zoom";

export default {
  name: "ProductImages",
  props: {
    images: { type: Array, required: true },
    name: { type: String, required: false },
    highlight: { type: Boolean, required: false },
    heartKey: { type: Number, required: false }
  },
  components: { PinchZoom },
  data() {
    return {
      selectedImage: {},
      dialog: false,
      disableZoom: true,
      swiperOptionThumbs: {
        slidesPerView: "auto",
        slidesPerGroup: 1,
        spaceBetween: 10,
        watchOverflow: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      }
    };
  },
  computed: {
    medium() {
      return this.selectedImage && this.selectedImage.medium;
    },
    large() {
      return this.selectedImage && this.selectedImage.large;
    }
  },
  methods: {
    openZoom() {}
  },
  mounted() {
    this.selectedImage = this.images[0];
  }
};
</script>
