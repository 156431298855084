import forEach from "lodash/forEach";
import join from "lodash/join";

function getItemsMapText(map, returnOnlyValue) {
  let values = [];
  forEach(map, v => {
    if (v.value) {
      if (v.name === "Valore") {
        values.push(v.value);
      } else {
        if (returnOnlyValue) {
          values.push(v.value);
        } else {
          // qui viene gestita lato frontend la richiesta di non mostrare la chiave Stabilimento.
          // E' un workaround non ottimale ma consente di assecondare la richiesta.
          if (v.name === "Stabilimento") {
            values.push(v.value);
          } else {
            values.push(`${v.name}: ${v.value}`);
          }
        }
      }
    }
  });
  return join(values, "<br/>");
}

export { getItemsMapText };
